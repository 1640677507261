import {
  StatusSideBarMainMenu,
  StatusSideBarMenuProps,
} from "@/models/global-models";
import { ROUTE_PATH } from "@/route-config/route-path";
import {
  COMPONENT_CLASS,
  ACCOUNT_GROUP_STEPS,
  ACCOUNT_TYPE_STEPS,
  ASSET_CATALOG_STEPS,
  BANK_PARAMS_STEPS,
  FINANCIAL_CALENDAR_STEPS,
  IC_ENTITY,
  SIDEBAR_STATUS,
  deepCopy,
  IC_FIN_ORG_STATUS_PREFIXES,
  GL_ENTITY,
  GL_STEPS,
  TC_STAGE_STATUS,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS,
  CRT_ENTITY,
} from "@/utils";

//component or feature configs
const FEE_DEPOSIT: StatusSideBarMainMenu = {
  name: "Fee component (deposit)",
  status: SIDEBAR_STATUS.not_started,
  featureId: 324234,
  componentClass: COMPONENT_CLASS["fee"],
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["FEE_GENERAL_SETUP"],
      subFeatureId: 4234,
      isDisabled: false,
      stageStatus: "feeGeneralFeatureSetup",
    },
    {
      name: "Fee configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["FEE_CONFIGURATION"],
      subFeatureId: 234,
      isDisabled: true,
      stageStatus: "feeConfig",
    },
    {
      name: "Service charge details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["FEE_SERVICE_CHARGE_DETAILS"],
      subFeatureId: 123,
      isDisabled: true,
      stageStatus: "serviceChargeDetails",
    },
    {
      name: "Earning analysis",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["FEE_EARNING_ANALYSIS"],
      subFeatureId: 900,
      isDisabled: true,
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const INTEREST_DEPOSIT: StatusSideBarMainMenu = {
  name: "Interest component (deposit)",
  status: SIDEBAR_STATUS.not_started,
  featureId: 324234,
  componentClass: COMPONENT_CLASS["interest"],
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_GENERAL_FEATURE_FORM"],
      subFeatureId: 4234,
      stageStatus: "interestGeneralFeatureSetup",
    },
    {
      name: "Foundational interest details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_FOUNDATIONAL_INTEREST_DETAILS"],
      subFeatureId: 234,
      stageStatus: "foundationalInterestDetails",
    },
    {
      name: "Indexed rate - foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_INDEX_RATE_FOUND_RATE"],
      subFeatureId: 123,
      stageStatus: "indexRateFoundationalDetails",
    },
    {
      name: "Indexed rate - rate adjustments & ranges",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_INDEX_RATE_ADJUSTMENT"],
      subFeatureId: 900,
      stageStatus: "indexRateAdjustmentRanges",
    },
    {
      name: "Indexed rate - rate offset & rounding",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_INDEX_RATE_REVIEW_OFFSET"],
      subFeatureId: 900,
      stageStatus: "indexRateOffsetRounding",
    },
    {
      name: "Indexed rate - review frequency",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_INDEX_RATE_REVIEW_FREQUENCY"],
      subFeatureId: 900,
      stageStatus: "indexRateReviewFrequency",
    },
    {
      name: "Promotional Rate - foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_PROMOTIONAL_FOUND_DETAIL_FORM"],
      subFeatureId: 900,
      stageStatus: "promotionalRateFoundationDetails",
    },
    {
      name: "Promotional Rate - rate adjustments & ranges",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_PROMOTIONAL_RATE_ADJUST_RANGE"],
      subFeatureId: 900,
      stageStatus: "promoRateAdjustmentsRanges",
    },
    {
      name: "Promotional Rate - rate offset & rounding",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_PROMOTIONAL_RATE_OFFSET_TERMS"],
      subFeatureId: 900,
      stageStatus: "promoRateOffsetsRounding",
    },
    {
      name: "Posting frequency & rounding",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_POST_FREQUENCY_ROUNDING"],
      subFeatureId: 900,
      stageStatus: "promoPostingFrequencyRounding",
    },
    {
      name: "Interest accrual details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_ACCURAL_RATE"],
      subFeatureId: 900,
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const LIMIT_DEPOSIT: StatusSideBarMainMenu = {
  name: "Limit component (deposit)",
  status: SIDEBAR_STATUS.not_started,
  href: "",
  componentClass: COMPONENT_CLASS["limit"],
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["LIMIT_GENERAL_FEATURE_FORM"],
      stageStatus: "limitGeneralFeatureSetup",
    },
    {
      name: "Account balance configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["LIMIT_ACCOUNT_BALANCE_CONFIG"],
      stageStatus: "accoutnBalanceConfiguration",
    },
    {
      name: "Restriction & limitations configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["LIMIT_RESTRICTION_CONFIG"],
      stageStatus: "restrictionConfiguration",
    },
    {
      name: "Accumulated transaction limit configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["LIMIT_ACCUMULAT_TRANSCATION_CONFIG"],
      stageStatus: "accumulatedTransactionLimitConfiguration",
    },
    {
      name: "Single transaction limit configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["LIMIT_SINGLE_TRANSACTION_CONFIG"],
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const REWARDS_DEPOSIT: StatusSideBarMainMenu = {
  name: "Rewards component (deposit)",
  status: SIDEBAR_STATUS.not_started,
  href: "",
  componentClass: COMPONENT_CLASS["reward"],
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REWARD_GENERAL_FEATURE_FORM"],
      stageStatus: "rewardGeneralFeatureSetup",
    },
    {
      name: "Reward configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REWARD_CONFIGURATION_FORM"],
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const NSF_DEPOSIT: StatusSideBarMainMenu = {
  name: "NSF component (deposit)",
  componentClass: COMPONENT_CLASS["nsf"],
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["NSF_GENERAL_SETUP"],
      stageStatus: "nsfGeneralFeatureSetup",
    },
    {
      name: "Negative limit details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["NSF_NEGATIVE_LIMITS"],
      stageStatus: "negativeLimitDetails",
    },
    {
      name: "Transaction code specifications",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["NSF_TRANSACTIONAL_CODE"],
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const TRANSACTION_DEPOSIT: StatusSideBarMainMenu = {
  name: "Transaction component (deposit)",
  componentClass: COMPONENT_CLASS["transactionDeposit"],
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["TRANSACTION_GENERAL_SETUP"],
      stageStatus: "transactionGeneralFeatureSetup",
    },
    {
      name: "Transaction code specifications",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["TRANSACTION_TRANSACTIONAL_CODE"],
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const CHARGE_OF_DEPOSIT: StatusSideBarMainMenu = {
  name: "Charge-off component (deposit)",
  componentClass: COMPONENT_CLASS["chargeOff"],
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["CHARGE_OFF_GENERAL_SETUP"],
      stageStatus: "chargeOffGeneralFeatureSetup",
    },
    {
      name: "Charge-Off timing and thresholds",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["CHARGE_OFF_TIMING_AND_THRESHOLD"],
      stageStatus: "chargeOffTimingThreshold",
    },
    {
      name: "Transaction code specifications",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["CHARGE_OFF_TRANSACTIONAL_CODE"],
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const TERM_DEPOSIT: StatusSideBarMainMenu = {
  name: "Term component (deposit)",
  componentClass: COMPONENT_CLASS["term"],
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["TERM_DEPOSIT_GENERAL_SETUP"],
      stageStatus: "termGeneralFeatureSetup",
    },
    {
      name: "Maturity configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["TERM_DEPOSIT_MATURITY_CONFIGURATION"],
      stageStatus: "maturityConfiguration",
    },
    {
      name: "Rollover product and rates configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["TERM_DEPOSIT_ROLL_OVER_PRODUCT"],
      stageStatus: "rollOverProdIntrestRateConfig",
    },
    {
      name: "Grace periods and extensions",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["TERM_DEPOSIT_GRACE_PERIODS"],
      stageStatus: "gracePeriodExtensions",
    },
    {
      name: "Penalties and adjustments",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["TERM_DEPOSIT_PENALTIES_ADJUSTMENT"],
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const RULES_DEPOSIT: StatusSideBarMainMenu = {
  name: "Rule component (deposit)",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["RULES"],
  stageStatus: "rules",
  componentClass: COMPONENT_CLASS["rules"],
  showInterstitial: true,
  interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
};

const REPAY_LOAN: StatusSideBarMainMenu = {
  name: "Repayment component (loan)",
  status: SIDEBAR_STATUS.not_started,
  featureId: 324234,
  componentClass: COMPONENT_CLASS["repay"],
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REPAYMENT_GENERAL_FEATURE_FORM"],
      subFeatureId: 4234,
      stageStatus: "repayGeneralFeatureSetup",
    },
    {
      name: "Interest & principal configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REPAYMENT_PRINCIPAL_INTEREST_FORM"],
      subFeatureId: 4234,
      stageStatus: "principlaInterestConfig",
    },
    {
      name: "Payment configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REPAYMENT_PAYMENT_CONFIGURATION_FORM"],
      subFeatureId: 4234,
      stageStatus: "paymentConfig",
    },
    {
      name: "Payment application method",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REPAYMENT_PAYMENT_DIRECTION_CHARGES"],
      subFeatureId: 4234,
      stageStatus: "paymentDirectionAndCharges",
    },
    {
      name: "Pre-payment configurations",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REPAYMENT_PRE_PAYMENT_DIRECTION_CHARGES"],
      subFeatureId: 4234,
      stageStatus: "prePaymentConfigurations",
    },
    {
      name: "Due item configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REPAYMENT_PAST_DUE_CONFIGURATIONS_AND_TRACKING"],
      subFeatureId: 4234,
      stageStatus: "pastDueConfigTracking",
    },
    {
      name: "Advanced component options",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REPAYMENT_ADVANCED_COMPONENT_OPTIONS"],
      subFeatureId: 4234,
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const COLLATERAL_LOAN: StatusSideBarMainMenu = {
  name: "Collateral component (loan)",
  status: SIDEBAR_STATUS.not_started,
  featureId: 324234,
  componentClass: COMPONENT_CLASS["collateral"],
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["COLLATERAL_GENERAL_COMPONENT_SETUP"],
      subFeatureId: 4234,
      stageStatus: "collateralGeneralFeatureSetup",
    },
    {
      name: "Security specifications",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["COLLATERAL_SECURITY_SECIFICATIONS_OPTIONS"],
      subFeatureId: 4234,
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const INTEREST_LOAN: StatusSideBarMainMenu = {
  name: "Interest component (loan)",
  status: SIDEBAR_STATUS.not_started,
  featureId: 324234,
  componentClass: COMPONENT_CLASS["interest"],
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_GENERAL_FEATURE_FORM"],
      subFeatureId: 4234,
      stageStatus: "interestGeneralFeatureSetup",
    },
    {
      name: "Foundational interest details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_FOUNDATIONAL_INTEREST_DETAILS"],
      subFeatureId: 234,
      stageStatus: "foundationalInterestDetails",
    },
    {
      name: "Indexed rate - foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_INDEX_RATE_FOUND_DETAIL"],
      subFeatureId: 123,
      stageStatus: "indexRateFoundationalDetails",
    },
    {
      name: "Indexed rate - rate adjustments & ranges",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_INDEX_RATE_ADJUSTMENT"],
      subFeatureId: 900,
      stageStatus: "indexRateAdjustmentRanges",
    },
    {
      name: "Indexed rate - rate offset & rounding",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_INDEX_RATE_REVIEW_OFFSET"],
      subFeatureId: 900,
      stageStatus: "indexRateOffsetRounding",
    },
    {
      name: "Indexed rate - review frequency",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_INDEX_RATE_REVIEW_FREQUENCY"],
      subFeatureId: 900,
      stageStatus: "indexRateReviewFrequency",
    },
    {
      name: "Introduction Rate - foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_PROMOTIONAL_FOUND_DETAIL_FORM"],
      subFeatureId: 900,
      stageStatus: "promotionalRateFoundationDetails",
    },
    {
      name: "Introduction Rate - rate adjustments & ranges",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_PROMOTIONAL_RATE_ADJUST_RANGE"],
      subFeatureId: 900,
      stageStatus: "promoRateAdjustmentsRanges",
    },
    {
      name: "Introduction Rate - rate offset & rounding",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_PROMOTIONAL_RATE_OFFSET_TERMS"],
      subFeatureId: 900,
      stageStatus: "promoRateOffsetsRounding",
    },
    {
      name: "Posting frequency & rounding",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_POST_FREQUENCY_ROUNDING"],
      subFeatureId: 900,
      stageStatus: "promoPostingFrequencyRounding",
    },
    {
      name: "Interest accrual details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_ACCURAL_RATE"],
      subFeatureId: 900,
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const FEE_LOAN: StatusSideBarMainMenu = {
  name: "Fee component (loan)",
  status: SIDEBAR_STATUS.not_started,
  featureId: 324234,
  componentClass: COMPONENT_CLASS["fee"],
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["FEE_GENERAL_SETUP_LOAN"],
      subFeatureId: 4234,
      stageStatus: "feeGeneralFeatureSetup",
    },
    {
      name: "Fee configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["FEE_CONFIGURATION_LOAN"],
      subFeatureId: 4234,
      stageStatus: "feeConfig",
    },
    {
      name: "Service charge details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["FEE_SERVICE_CHARGE_DETAILS_LOAN"],
      subFeatureId: 4234,
      stageStatus: "serviceChargeDetails",
    },
    {
      name: "Earning analysis",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["FEE_EARNING_ANALYSIS_LOAN"],
      subFeatureId: 4234,
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const RULES_LOAN: StatusSideBarMainMenu = {
  name: "Rule component (loan)",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["RULES"],
  stageStatus: "rules",
  componentClass: COMPONENT_CLASS["rules"],
  showInterstitial: true,
  interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
};

const PRODUCT_BASICS: StatusSideBarMainMenu = {
  sectionName: "Additional product attributes",
  name: "Product basics",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["PRODUCT_ATTRIBUTE_BASIC_FORM"],
  stageStatus: "productBasics",
  componentClass: "productBasics",
};

const CADENCES: StatusSideBarMainMenu = {
  name: "Cadences",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["PRODUCT_ATTRIBUTE_CADENCES_FORM"],
  stageStatus: "cadences",
  componentClass: "cadences",
};

const ELIGIBILITY_AND_COMPONENTS_CONFIGURATION: StatusSideBarMainMenu = {
  name: "Eligibility & features configuration",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH[
    "PRODUCT_ATTRIBUTE_ELIGIBILITY_AND_COMPONENTS_CONFIGURATION"
  ],
  stageStatus: "eligibilityAndComponentsConfiguration",
  componentClass: "eligibilityAndComponentsConfiguration",
};

const ADDITIONAL_PRODUCTS_FEATURES: StatusSideBarMainMenu = {
  name: "Additional product details",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["PRODUCT_ATTRIBUTE_PRODUCT_FEATURES"],
  stageStatus: "additionalProductFeatures",
  componentClass: "additionalProductFeatures",
};

const REGULATORY_ADHERENCE_LIMITATIONS: StatusSideBarMainMenu = {
  name: "Regulatory adherence & limitations",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["PRODUCT_ATTRIBUTE_REGULATORY_ADHERENCE"],
  stageStatus: "regulatoryAdherenceLimitations",
  componentClass: "regulatoryAdherenceLimitations",
};

const LOAN_DELINQUENCY: StatusSideBarMainMenu = {
  name: "Loan delinquency",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["PRODUCT_ATTRIBUTE_LOAN_DELINQUENCY"],
  stageStatus: "loanDelinquency",
  componentClass: "loanDelinquency",
};

export const DEPOSIT_COMPONENT_CLASS_FOR_SIDEBAR: Record<
  string,
  StatusSideBarMainMenu
> = {
  componentFee: deepCopy(FEE_DEPOSIT),
  componentInt: deepCopy(INTEREST_DEPOSIT),
  componentLimit: deepCopy(LIMIT_DEPOSIT),
  componentReward: deepCopy(REWARDS_DEPOSIT),
  componentNsf: deepCopy(NSF_DEPOSIT),
  componentRepay: deepCopy({
    ...REPAY_LOAN,
    name: "Repayment component (deposit)",
  }),
  componentCollateral: deepCopy({
    ...COLLATERAL_LOAN,
    name: "Collateral component (deposit)",
  }),
  componentTd: deepCopy(TERM_DEPOSIT),
  componentDepChrgOff: deepCopy(CHARGE_OF_DEPOSIT),
  componentTrnOpt: deepCopy(TRANSACTION_DEPOSIT),
  componentRules: deepCopy(RULES_DEPOSIT),
};

export const LOAN_COMPONENT_CLASS_FOR_SIDEBAR: Record<
  string,
  StatusSideBarMainMenu
> = {
  componentFee: deepCopy(FEE_LOAN),
  componentInt: deepCopy(INTEREST_LOAN),
  componentLimit: deepCopy({
    ...LIMIT_DEPOSIT,
    name: "Limit component (loan)",
  }),
  componentReward: deepCopy({
    ...REWARDS_DEPOSIT,
    name: "Rewards component (loan)",
  }),
  componentNsf: deepCopy({ ...NSF_DEPOSIT, name: "NSF Component (loan)" }),
  componentRepay: deepCopy(REPAY_LOAN),
  componentCollateral: deepCopy(COLLATERAL_LOAN),
  componentTd: deepCopy({ ...TERM_DEPOSIT, name: "Term Component (loan)" }),
  componentDepChrgOff: deepCopy({
    ...CHARGE_OF_DEPOSIT,
    name: "Charge-off Component (loan)",
  }),
  componentTrnOpt: deepCopy({
    ...TRANSACTION_DEPOSIT,
    name: "Transaction Component (loan)",
  }),
  componentRules: deepCopy({
    ...RULES_LOAN,
    name: "Rule component (loan)",
  }),
};

export const DEFAULT_ADDITIONAL_PRODUCT_ATTRIBUTES: StatusSideBarMainMenu[] = [
  PRODUCT_BASICS,
  CADENCES,
  ELIGIBILITY_AND_COMPONENTS_CONFIGURATION,
  ADDITIONAL_PRODUCTS_FEATURES,
  REGULATORY_ADHERENCE_LIMITATIONS,
  LOAN_DELINQUENCY,
];

// Institution config
const ACCOUNT_GROUP_SIDEBAR = {
  id: IC_ENTITY.account_group,
  sectionName: "Details",
  sectionNameTooltipDesc: "Institutional Configuration",
  name: "Account group",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "Account group foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_GROUP_FOUNDATIONAL_DETAILS"]}`,
      stageStatus: ACCOUNT_GROUP_STEPS.acc_grp_foundation_details,
      description: "Classify accounts with similar processing parameters",
    },
    {
      name: "Numbering & identification",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_GROUP_NUMBERING_IDENTIFICATION"]}`,
      stageStatus: ACCOUNT_GROUP_STEPS.acc_grp_number_identification,
      description:
        "Define important classification and categorization specifications",
    },
    {
      name: "Composite & field configuration",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_GROUP_COMPOSITE_FIELD_CONFIGURATION"]}`,
      stageStatus: ACCOUNT_GROUP_STEPS.acc_grp_composite_field,
      description:
        "Specify details around how account numbers are structured and displayed",
    },
    {
      name: "Advanced configurations",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_GROUP_ADVANCED_CONFIGURATION"]}`,
      stageStatus: ACCOUNT_GROUP_STEPS.acc_grp_advanced_config,
      description:
        "Complete final questions to manage risk, compliance, and improve operational efficiency",
    },
  ],
};

const ASSET_CATALOG_SIDEBAR = {
  id: IC_ENTITY.asset_catalog,
  sectionName: "Details",
  sectionNameTooltipDesc: "Institutional Configuration",
  name: "Asset catalog",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "Asset catalog foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ASSET_CATALOG_FOUNDATIONAL_DETAILS"]}`,
      stageStatus: ASSET_CATALOG_STEPS.asset_cat_foundation_detail,
      description:
        "Properly classify, identify attributes, and evaluate assets for your institution",
    },
    {
      name: "Asset origin & classification",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ASSET_ORIGIN_AND_CLASSIFICATION"]}`,
      stageStatus: ASSET_CATALOG_STEPS.asset_cat_origin_classification,
      description:
        "Provide industry sector information for data analysis and reporting & additional information",
    },
    {
      name: "Financial specifications",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ASSET_FINANCIAL_SPECIFICATION"]}`,
      stageStatus: ASSET_CATALOG_STEPS.asset_cat_fin_spec,
      description: "Define details when an asset is used as collateral",
    },
    {
      name: "General Ledger details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ASSET_GENERAL_LEDGER_DETAILS"]}`,
      stageStatus: ASSET_CATALOG_STEPS.asset_cat_gen_ledger,
      description:
        "Ensure a clear and comprehensive view of your assets in relation to your general ledger account group and numbers",
    },
  ],
};

const ACCOUNT_TYPE_SIDEBAR = {
  id: IC_ENTITY.account_type,
  sectionName: "Details",
  sectionNameTooltipDesc: "Institutional Configuration",
  name: "Account types",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "Account type foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_TYPES_FOUNDATIONAL_DETAILS"]}`,
      stageStatus: ACCOUNT_TYPE_STEPS.acc_type_foundation_detail,
      description:
        "Begin setting up your account type for clear classification to ensure transactions are recorded accurately",
    },
    {
      name: "Account type relationship foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_TYPES_RELATIONSHIP_FOUNDATIONAL_DETAILS"]}`,
      stageStatus: ACCOUNT_TYPE_STEPS.acc_type_reln_foundation_detail,
      description:
        "Define inter-party connections, enabling accurate reporting and analysis",
    },
    {
      name: "Account type relationship party specifications",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_TYPES_RELATIONSHIP_PARTY_SPECIFICATIONS"]}`,
      stageStatus: ACCOUNT_TYPE_STEPS.acc_type_reln_party_spec,
      description:
        "Important for account management, relationship management and risk assessment",
    },
    {
      name: "Account type relationship additional selections",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_TYPES_RELATIONSHIP_ADDITIONAL_SELECTIONS"]}`,
      stageStatus: ACCOUNT_TYPE_STEPS.acc_type_reln_add_selections,
      description:
        "Important for account management, relationship management and risk assessment",
    },
    {
      name: "Add limit component",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.IC_ACCOUNT_TYPES_ADD_LIMIT,
      stageStatus: ACCOUNT_TYPE_STEPS.acct_type_limits,
      description:
        "Determine how your institution will set appropriate limits, manage risk, and provide tailored account offerings",
    },
    {
      name: "Account type relationship Summary",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.IC_ACCOUNT_TYPES_RELATIONSHIP_SUMMARY,
      stageStatus: ACCOUNT_TYPE_STEPS.acct_type_summary,
      description:
        "This is a summary view of all the relationship details for this account type. Add additional relationship type as needed.",
    },
  ],
};

const FINANCIAL_CALENDAR_SIDEBAR = {
  id: IC_ENTITY.financial_calendar,
  sectionName: "Details",
  sectionNameTooltipDesc: "Institutional Configuration",
  name: "Financial calendars",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "Financial calendar foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_FINANCIAL_CALENDER_FOUNDATIONAL_DETAILS"]}`,
      stageStatus: FINANCIAL_CALENDAR_STEPS.fin_cal_foundation_details,
      description:
        "Establish your business and holiday calendars to define operating hours",
    },
    {
      name: "Business day configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["IC_FINANCIAL_CALENDER_BUSINESS_DAY_CONFIG"],
      stageStatus: FINANCIAL_CALENDAR_STEPS.fin_cal_business_day_config,
      description:
        "Establish the operational calendar to meet regulatory and transaction requirements",
    },
    {
      name: "Holiday configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["IC_FINANCIAL_CALENDER_HOLIDAY_CONFIGURATION"],
      stageStatus: FINANCIAL_CALENDAR_STEPS.fin_cal_holiday_config,
      description:
        "Account for holidays to meet regulatory and transaction requirements",
    },
  ],
};

const FINANCIAL_ORGANIZATION_SIDEBAR = {
  sectionName: "Details",
  sectionNameTooltipDesc: "Institutional Configuration",
  id: IC_ENTITY.financial_organization,
  name: "Financial institution setup",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "General party parameters",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.GENERAL_PARTY_PARAMETER,
      stageStatus: IC_FIN_ORG_STATUS_PREFIXES.gen_party,
    },
    {
      name: "General organization party parameters",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.IC_GENERAL_ORGANIZATION_PARTY,
      stageStatus: IC_FIN_ORG_STATUS_PREFIXES.gen_org_party,
    },
    {
      name: "Financial institution-specific parameters",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM,
      stageStatus: IC_FIN_ORG_STATUS_PREFIXES.fin_org_spec,
    },
  ],
};

const BANK_PARAMS_SIDEBAR = {
  id: IC_ENTITY.bank_parameters,
  sectionName: "Details",
  sectionNameTooltipDesc: "Institutional Configuration",
  name: "Financial institution (bank) parameters",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "Foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_FOUNDATION_DETAILS"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_foundational_details,
      description:
        "Capture essential information, including time zones, operational calendars, and default network settings for precise institutional alignment",
    },
    {
      name: "Rates, currencies, & identifiers",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_RATES_CURRENCIES_IDENTIFIERS"]}`,
      stageStatus:
        BANK_PARAMS_STEPS.bank_params_rates_currencies_and_identifiers,
      description:
        "Detail the institution's approach towards withholding rates, reporting currencies, and asset management",
    },
    {
      name: "Cutoffs, limits & thresholds",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_CUTOFF_LIMITS_THRESHOLD"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_cutoffs_limits_thresholds,
      description:
        "Define the bank's critical operational thresholds, including tax reporting, overdraft limits, and financial cutoff times to ensure compliance and risk management",
    },
    {
      name: "Additional specifications",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_ADDITIONAL_SPECIFICATION"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_additional_specifications,
      description:
        "Outline specific bank operational codes and durations, alongside system-wide authorizations and journaling practices",
    },
    {
      name: "Base savings rates",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_BASE_SAVING_RATE"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_base_savings_rates,
      description:
        "Establish savings rate parameters, including rate offsets, minimum and maximum values, and applicable indices for interest calculation",
    },
    {
      name: "Loan penalty rate",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_LOAN_PENALTY_RATE"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_loan_penalty_rate,
      description:
        "Set penalty rate parameters for loans, including rate offsets, minimum and maximum values, key rounding methods, and index references",
    },
    {
      name: "US financial institution (bank) parameters & information",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_US_FINANCIAL_INST_PARAMS_INFO"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_us_bank_params_info,
      description:
        "Set up key parameter information specific to a U.S. institution. Begin with incorporating credit bureau reporting features, as applicable",
    },
    {
      name: "[US] FDIC370 related fields",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_FDIC_370_RELATED_FIELD"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_fdic_370_related_fields,
      description:
        "Configure specific FDIC370 compliance fields, including authorized roles, data paths, and codes to meet regulatory requirements",
    },
    {
      name: "[US] SBA lending party email",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_SBA_LENDING_PARTY_EMAIL"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_sba_lending_party_email,
      description: "",
    },
    {
      name: "[US] SBA lending party phone",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_SBA_LENDING_PARTY_PHONE"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_sba_lending_party_phone,
      description:
        "If small business lending is applicable, define a party phone",
    },
    {
      name: "[US] SCRA properties",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_SCRA_PROPERTIES"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_scra_properties,
      description:
        "If small business lending is applicable, define a party email",
    },
    {
      name: "General Ledger extract file formats",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_GL_EXTRACT_FILE_FORMATE"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_gl_extract_file_formats,
      description:
        "Customize extract file formats, including naming conventions and summary indicators for financial reporting",
    },
    {
      name: "Tax party email contact",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_TAX_PARTY_EMAIL_CONTACT"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_tax_party_email_contact,
      description:
        "Specify the email contact of the party responsible for the institution's tax reporting",
    },
    {
      name: "Tax party phone contact",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_TAX_LENDING_PARTY_PHONE"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_tax_party_phone_contact,
      description:
        "Specify the phone contact of the party responsible for the institution's tax reporting",
    },
    {
      name: "Priority posting",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_PRIORITY_POSTING"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_priority_posting,
      description:
        "Determine the transaction posting protocols, including frequency, order, and network priority to optimize operational efficiency",
    },
  ],
};

// NOTE: If any new steps are added under these flows, this list needs to be updated to show the correct step count
// DON'T CHANGE: Ordering matters
export const IC_FIN_INST_SUB_STEP_ROUTES = {
  [IC_FIN_ORG_STATUS_PREFIXES.gen_party]: [
    {
      path: ROUTE_PATH.GENERAL_PARTY_PARAMETER_IDENTIFICATION,
      description: "Begin identifying party-level information and attributes",
    },
    {
      path: ROUTE_PATH.GENERAL_PARTY_PARAMETER_PARTY_EMAILS_DETAILS,
      description: "Provide party email contact details",
    },
    {
      path: ROUTE_PATH.GENERAL_PARTY_PARAMETER_PARTY_PHONE_DETAILS,
      description: "Provide party phone contact details",
    },
    {
      path: ROUTE_PATH.GENERAL_PARTY_PARAMETER_TAX_SPECIFICATION,
      description:
        "Define tax specifications for accurate reporting and regulatory compliance",
    },
    {
      path: ROUTE_PATH.GENERAL_PARTY_PARAMETER_GENERAL_PARTY_SPECIFICATION,
      description: "Provide additional information on data sharing",
    },
    {
      path: ROUTE_PATH.GENERAL_PARTY_PARAMETER_LOCATION_CONTACT_INFORMATION,
      description: "Provide additional location and contact details",
    },
    {
      path: ROUTE_PATH.GENERAL_PARTY_PARAMETER_INTERNATIONAL_TRANSFER_INTENT,
      description:
        "Define what countries the party intends to transfer funds to and from",
    },
  ],
  [IC_FIN_ORG_STATUS_PREFIXES.gen_org_party]: [
    {
      path: ROUTE_PATH.IC_GENERAL_ORGANIZATION_PARTY_IDENTIFICATION,
      description:
        "Outline the organization's identity and operational metrics, including legal names, trade names, and workforce size",
    },
    {
      path: ROUTE_PATH.IC_GENERAL_ORGANIZATION_PARTY_TAX_SPECIFICATION,
      description: "Detail the organization tax exemption status and type",
    },
    {
      path: ROUTE_PATH.IC_GENERAL_ORGANIZATION_PARTY_SPECIFICATION,
      description:
        "Characterize the organization’s legal and operational status, including ownership details, market positioning, and fiscal year-end",
    },
    {
      path: ROUTE_PATH.IC_GENERAL_ORGANIZATION_PARTY_LOCATION_AND_CONTACT,
      description:
        "Document the organization’s digital footprint and regional coding",
    },
  ],
  [IC_FIN_ORG_STATUS_PREFIXES.fin_org_spec]: [
    {
      path: ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_FIN_ORG_IDENTIFICATION,
      description:
        "Identify the financial organization to facilitate accurate and secure transactions",
    },
    {
      path: ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_FIN_ORG_OTHER_IDENTIFICATION,
      description:
        "Append additional identifications and specify the ID type for ease of recognition",
    },
    {
      path: ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_FIN_ORG_SPECIFICATIONS,
      description:
        "Classify the financial organization within the banking system by defining its routing category for transaction processing",
    },
    {
      path: ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_IDENTIFICATION,
      description:
        "Begin setting up a corresponding network by providing key identification details",
    },
    {
      path: ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_CORRS_DESTINATION_NETWORK,
      description:
        "Set up the destination network to facilitate communication and information exchange",
    },
    {
      path: ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_CORRS_ORIGIN_NETWORK,
      description:
        "Set up the origin network to facilitate communication and information exchange",
    },
    {
      path: ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_SUMMARY,
      description: "Review your corresponding network configuration(s)",
    },
    {
      path: ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_RULES,
      description: "Set up rules at the institution level",
    },
  ],
};

export const IC_NAVBAR_MAP = {
  [IC_ENTITY.account_group]: ACCOUNT_GROUP_SIDEBAR,
  [IC_ENTITY.asset_catalog]: ASSET_CATALOG_SIDEBAR,
  [IC_ENTITY.account_type]: ACCOUNT_TYPE_SIDEBAR,
  [IC_ENTITY.financial_calendar]: FINANCIAL_CALENDAR_SIDEBAR,
  [IC_ENTITY.financial_organization]: FINANCIAL_ORGANIZATION_SIDEBAR,
  [IC_ENTITY.bank_parameters]: BANK_PARAMS_SIDEBAR,
};

const GL_ACCOUNTING_SEGMENTS = {
  sectionName: "General Ledger",
  sectionNameTooltipDesc: "General Ledger",
  name: "Accounting Segments",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["GL_ACCOUNTING_SEGMENTS"],
  stageStatus: GL_STEPS.accounting_segments,
};

const GL_ACCOUNTS = {
  name: "Accounts",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["GL_ACCOUNTS"],
  stageStatus: GL_STEPS.accounts,
};

const GL_SYSTEM_ACCOUNTS = {
  name: "System Accounts",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["GL_SYSTEM_ACCOUNTS"],
  stageStatus: GL_STEPS.system_accounts,
};

const GL_SETS = {
  name: "Sets",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["GL_SETS"],
  stageStatus: GL_STEPS.sets,
};

export const GENERAL_LEDGER_SIDE_BAR_PROPS: StatusSideBarMenuProps = {
  mainMenuList: [
    GL_ACCOUNTING_SEGMENTS,
    GL_ACCOUNTS,
    GL_SYSTEM_ACCOUNTS,
    GL_SETS,
  ],
};

export const CRT_SIDE_BAR_PROPS: StatusSideBarMenuProps = {
  mainMenuList: [
    {
      id: CRT_ENTITY.general_setup,
      sectionName: "Customer relationship types",
      sectionNameTooltipDesc: "Customer relationship types",
      name: "General setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["CRT_GENERAL_SETUP"],
      stageStatus: CRT_ENTITY.general_setup,
      description:
        "Identify and articulate the nature of customer relationships for reporting and data validation purposes",
    },
    {
      id: CRT_ENTITY.party_relationships,
      name: "Party relationships",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["CRT_PARTY_RELATIONSHIPS"],
      stageStatus: CRT_ENTITY.party_relationships,
      description:
        "Set relationship types, roles, and party limits to structure how parties relate within the system, enhancing relationship management",
    },
  ],
};

const GL_ACCOUNTING_SEGMENTS_SIDEBAR = {
  sectionName: "Details",
  sectionNameTooltipDesc: "General Ledger",
  name: "Accounting Segments",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["GL_ACCOUNTING_SEGMENTS"],
  stageStatus: GL_STEPS.accounting_segments,
  description:
    "Establish your accounting segment to organize your financial data and structure your general ledger",
};

const GL_ACCOUNTS_SIDEBAR = {
  sectionName: "Details",
  sectionNameTooltipDesc: "General Ledger",
  name: "Accounts",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["GL_ACCOUNTS"],
  stageStatus: GL_STEPS.accounts,
  description:
    "Define your accounts, giving them unique identifiers to ensure accurate reporting and compliance",
};

const GL_SYSTEM_ACCOUNTS_SIDEBAR = {
  sectionName: "Details",
  sectionNameTooltipDesc: "General Ledger",
  name: "System Accounts",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["GL_SYSTEM_ACCOUNTS"],
  stageStatus: GL_STEPS.system_accounts,
  description:
    "Establish placeholder accounts that are used between when an exception occurs and a transaction settles",
};

const GL_SETS_SIDEBAR = {
  sectionName: "Details",
  sectionNameTooltipDesc: "General Ledger",
  name: "Sets",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["GL_SETS"],
  stageStatus: GL_STEPS.sets,
  description:
    "Group your accounts to control the transactional & balance-related activities of a particular position",
};

export const GL_NAVBAR_MAP = {
  [GL_ENTITY.accounting_segments]: GL_ACCOUNTING_SEGMENTS_SIDEBAR,
  [GL_ENTITY.accounts]: GL_ACCOUNTS_SIDEBAR,
  [GL_ENTITY.system_accounts]: GL_SYSTEM_ACCOUNTS_SIDEBAR,
  [GL_ENTITY.sets]: GL_SETS_SIDEBAR,
};
export const TRANS_CODE_SIDE_BAR_MENU: StatusSideBarMenuProps = {
  mainMenuList: [
    {
      sectionName: "Transaction Code",
      sectionNameTooltipDesc: "Transaction Code",
      name: "Foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.TC_FOUNDATIONAL_DETAILS,
      stageStatus: TC_STAGE_STATUS.foundational_details,
      id: TC_STAGE_STATUS.foundational_details,
      description:
        "Establish key identifiers, included networks, and transaction limits",
    },
    {
      name: "Transaction tag setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.TC_TAG_SETUP,
      stageStatus: TC_STAGE_STATUS.tag_setup,
      id: TC_STAGE_STATUS.tag_setup,
      description:
        "If desired, add an additional identifier when the transaction is posted",
    },
    {
      name: "Transaction entry setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.TC_ENTRY_SETUP,
      stageStatus: TC_STAGE_STATUS.entry_setup,
      id: TC_STAGE_STATUS.entry_setup,
      subMenuList: [
        {
          name: "Transaction entry foundational details",
          status: SIDEBAR_STATUS.not_started,
          href: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_FOUNDATIONAL_DETAILS"]}`,
          stageStatus: TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_foundational,
          description:
            "Ensure accurate and consistent transaction entries that balance debits and credits",
        },
        {
          name: "Transaction entry advanced setup",
          status: SIDEBAR_STATUS.not_started,
          href: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_ADVANCED_SETUP"]}`,
          stageStatus: TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_advanced,
          description:
            "Continue setting up important details related to position identification, funding, fees, and associated set codes for risk management and regulatory compliance",
        },
        {
          name: "Statistical group expression entries",
          status: SIDEBAR_STATUS.not_started,
          href: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_STATISTIC_GROUP"]}`,
          stageStatus:
            TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_statistical_group,
          description:
            "Track transaction statistics for data analytics and monitoring",
        },
        {
          name: "Accounting segments",
          status: SIDEBAR_STATUS.not_started,
          href: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_ACCOUNTING_SEGMENTS"]}`,
          stageStatus:
            TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_account_segments,
          description:
            "Add an accounting segment to this transaction code associated with the structure of your general ledger",
        },
        {
          name: "Templates",
          status: SIDEBAR_STATUS.not_started,
          href: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_TEMPLATES"]}`,
          stageStatus: TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_template,
          description:
            "Enhance the user experience by providing distinct description displays for each transaction entry",
        },
        {
          name: "Hold specifications",
          status: SIDEBAR_STATUS.not_started,
          href: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_HOLD_SPECIFICATIONS"]}`,
          stageStatus:
            TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_hold_specification,
          description:
            "Configure key controls for transactions to enable security, risk management, and regulatory compliance",
        },
        {
          name: "Field specifications (immutable vs required)",
          status: SIDEBAR_STATUS.not_started,
          href: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_FIELD_SPECIFICATIONS"]}`,
          stageStatus:
            TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_field_specification,
          description:
            "Identify properties that are exempt from the impacts of a posted transaction or necessary when using this transaction code",
        },
        {
          name: "Transaction entry summary",
          status: SIDEBAR_STATUS.not_started,
          href: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_SUMMARY"]}`,
          stageStatus: TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_summary,
          description:
            "This is a summary view of all the transaction entries for this transaction code. Add additional transaction entries as needed.",
        },
      ],
    },
  ],
};
